export default {
    coverImage: require('./image03376.jpg'),
    outside1: require('./image03402.jpg'),
    outside2: require('./image03377.jpg'),
    drone1: require('./drone1.png'),
    drone2: require('./drone2.png'),
    drone3: require('./drone3.png'),
    drone4: require('./drone4.png'),
    accom1: require('./image03402.jpg'),
    kitchen1: require('./image03369.jpg'),
    gallery1: require('./image03367.jpg'),
    gallery2: require('./image03368.jpg'),
    gallery3: require('./image03371.jpg'),
    gallery4: require('./image03372.jpg'),
    gallery5: require('./image03373.jpg'),
    gallery6: require('./image03374.jpg'),
    gallery7: require('./image03375.jpg'),
    gallery8: require('./image03376.jpg'),
    gallery9: require('./image03378.jpg'),
    gallery10: require('./image03380.jpg'),
    gallery11: require('./image03381.jpg'),
    gallery12: require('./image03382.jpg'),
    gallery13: require('./image03383.jpg'),
    gallery14: require('./image03384.jpg'),
    gallery15: require('./image03385.jpg'),
    gallery16: require('./image03386.jpg'),
    gallery17: require('./image03387.jpg'),
    gallery18: require('./image03388.jpg'),
    gallery19: require('./image03389.jpg'),
    gallery20: require('./image03390.jpg'),
    gallery21: require('./image03391.jpg'),
    gallery22: require('./image03392.jpg'),
    gallery23: require('./image03393.jpg'),
    gallery24: require('./image03394.jpg'),
    gallery25: require('./image03395.jpg'),
    gallery26: require('./image03396.jpg'),
    gallery27: require('./image03397.jpg'),
    gallery28: require('./image03398.jpg'),
    gallery29: require('./image03399.jpg'),
    gallery30: require('./image03400.jpg'),
    gallery31: require('./image03401.jpg'),
    gallery32: require('./image03403.jpg')
}