import HomepageCards from "../components/Homepage/HomepageCards";
import Footer from "../components/Navigation/Footer";
import NavBar from "../components/Navigation/NavBar";
import ReactDOM from 'react-dom';


function Home() {
    return (
        <div>
            <NavBar />
            <div class="bg-gray-100	p-14 border-b border-neutral-300">

                <h1 class="align-middle	text-center text-4xl tracking-tight font-normal text-gray-900 mb-4 ">Welcome to Eala Lodge</h1>
                <p class=" max-w-2xl mx-auto text-center">Nestled among trees with its own trout lake just outside the village of Castlerock in County Derry/Londonderry. Eala lodge is perfect for a rural and seaside luxurious getaway. The property is particulary suited to large family groups, golfing parties and other groups (including motorcyclists).</p>
            </div>
            <HomepageCards />
            <Footer />
        </div>
    );
}

export default Home;