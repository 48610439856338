import HomepageCards from "./components/Homepage/HomepageCards";
import NavBar from "./components/Navigation/NavBar";
import { Route, BrowserRouter as Router, Routes as Switch } from "react-router-dom";
import NavBarAlt from "./components/Navigation/NavBarAlt";
import Home from "./pages/Home";
import About from "./pages/About";
import Accommodation from "./pages/Accommodation";
import ContactUs from "./pages/ContactUs";
import CookingExp from "./pages/CookingExp";
import ContactUsSuccess from "./pages/ContactUsSucess";

function App() {
    return (
        <Router>
            <Switch>
                <Route path="/" element={<Home />} />
                <Route path="/about" element={<About />} />
                <Route path="/accommodation" element={<Accommodation />} />
                <Route path="/contactus" element={<ContactUs />} />
                <Route path="/cookingexperiences" element={<CookingExp />} />
                <Route path="/contactsuccess" element={<ContactUsSuccess />} />
            </Switch>
        </Router>
    );
}

export default App;