import 'bootstrap/dist/css/bootstrap.css';
import React, { useState } from 'react';
import Button from 'react-bootstrap/Button';
import NavBarAlt from "../components/Navigation/NavBarAlt";
import ReactBnbGallery from 'react-bnb-gallery';
import 'react-bnb-gallery/dist/style.css';
import Footer from '../components/Navigation/Footer';
import { Card } from 'react-bootstrap';
import { FaBaby, FaBook, FaCalendar, FaCalendarAlt, FaCarAlt, FaDumbbell, FaEthernet, FaMusic, FaPaw, FaTv, FaUtensils, FaWater, FaChair, FaCoffee, FaSoap, FaBath, FaToiletPaper} from 'react-icons/fa';
import { IoBalloon } from 'react-icons/io5';
import { GiToaster} from 'react-icons/gi';
import { MdKayaking, MdOutlinePedalBike, MdOutdoorGrill, MdOutlineFireplace, MdOutlineGrass, MdKitchen, MdDinnerDining, MdMicrowave, MdIron, MdOutlineLocalLaundryService, MdAir, MdMonitor, MdToys} from 'react-icons/md'
import { BsSafe2Fill} from 'react-icons/bs'
import { GiDirectorChair, GiWoodenFence} from 'react-icons/gi'


import imagepaths from '../images/imagepaths';
import { icons } from 'react-icons/lib';

function Accommodation() {

    const [isOpen, setIsOpen] = useState(false);
    // const PHOTOS = [
    //     'https://cdn.discordapp.com/attachments/501158384964468739/999299664123801661/leetest2.jpg',
    //     'https://a0.muscache.com/im/pictures/a550f2e9-ff94-4685-b89b-d29e1802a41d.jpg?im_w=1200',
    //     'https://a0.muscache.com/im/pictures/6d9d7297-07ba-4edf-abd3-aeb2ae2e3413.jpg?im_w=1440',
    //     'https://a0.muscache.com/im/pictures/cc5651b8-ac08-4cfe-add5-90c823af8b67.jpg?im_w=1440',
    //     'https://a0.muscache.com/im/pictures/7ceb6405-f0dd-486f-98bb-4c3f6f6e374b.jpg?im_w=1440',
    //     'https://a0.muscache.com/im/pictures/9c8a6fb3-ce7d-47af-b590-363d2a102da7.jpg?im_w=1440',
    //     'https://a0.muscache.com/im/pictures/5d8cb028-fa44-4b6b-9b6d-e770fb35ad5e.jpg?im_w=1440',
    //     'https://a0.muscache.com/im/pictures/4b943da1-834e-4a10-a685-21ee955d2f0f.jpg?im_w=1440',
    // ];

    const PHOTOS = [
        { photo: imagepaths.drone1 },
        { photo: imagepaths.drone2 },
        { photo: imagepaths.drone3 },
        { photo: imagepaths.drone4 },
        { photo: imagepaths.kitchen1 },
        { photo: imagepaths.gallery1 },
        { photo: imagepaths.gallery2 },
        { photo: imagepaths.gallery3 },
        { photo: imagepaths.gallery4 },
        { photo: imagepaths.gallery5 },
        { photo: imagepaths.gallery6 },
        { photo: imagepaths.gallery7 },
        { photo: imagepaths.gallery8 },
        { photo: imagepaths.gallery9 },
        { photo: imagepaths.gallery10 },
        { photo: imagepaths.gallery11 },
        { photo: imagepaths.gallery12 },
        { photo: imagepaths.gallery13 },
        { photo: imagepaths.gallery14 },
        { photo: imagepaths.gallery15 },
        { photo: imagepaths.gallery16 },
        { photo: imagepaths.gallery17 },
        { photo: imagepaths.gallery18 },
        { photo: imagepaths.gallery19 },
        { photo: imagepaths.gallery20 },
        { photo: imagepaths.gallery21 },
        { photo: imagepaths.gallery22 },
        { photo: imagepaths.gallery23 },
        { photo: imagepaths.gallery24 },
        { photo: imagepaths.gallery25 },
        { photo: imagepaths.gallery26 },
        { photo: imagepaths.gallery27 },
        { photo: imagepaths.gallery28 },
        { photo: imagepaths.gallery29 },
        { photo: imagepaths.gallery30 },
        { photo: imagepaths.gallery31 },
        { photo: imagepaths.gallery32 }
    ]

    return (
        <div>
            <NavBarAlt title="Accommodation" image={imagepaths.accom1} />
            <h1 class="align-middle	text-center text-4xl tracking-tight font-extrabold text-gray-900 mt-4 mb-4"></h1>
            <div class="max-w-6xl mx-auto">
                <h1 class="align-middle	text-3xl tracking-tight font-bold text-gray-900 mb-2 ml-2 mr-2">About the property</h1>

                <p class="font-sans mb-4 ml-2 mr-2">Nestled among trees with its own trout lake just outside the village of Castlerock in County Derry/Londonderry. Eala lodge is perfect for a rural and seaside luxurious getaway. The property is particulary suited to large family groups, golfing parties and other groups (including motorcyclists).

                    Two sitting rooms (with views of the lake, coastline and the sea) equipped with TV and Wifi. Well equipped large luxury kitchen.</p>
                <ReactBnbGallery
                    show={isOpen}
                    photos={PHOTOS}
                    onClose={() => setIsOpen(false)}
                />
            </div>
            <div class="grid grid-cols-1 md:grid-cols-2 max-w-6xl mx-auto">
                <div class="p-2">
                    <img class="h-full w-full object-cover rounded-lg" src={imagepaths.drone1} alt="" />
                </div>
                <div class="w-full h-full">
                    <div class="grid grid-cols-2 mx-auto">
                        <div class="p-2">
                            <img class="w-full object-cover h-64 rounded-lg" src={imagepaths.drone2} alt="" />
                        </div>
                        <div class="p-2">
                            <img class="w-full object-cover h-64 rounded-lg " src={imagepaths.drone3} alt="" />
                        </div><div class="p-2">
                            <img class="w-full object-cover h-64 rounded-lg" src={imagepaths.drone4} alt="" />
                        </div>
                        <div class="p-2 relative">
                            <img class="w-full object-cover h-64 rounded-lg" src={imagepaths.kitchen1} alt="" />
                            <button class="bg-black/50 hover:bg-black/80 underline absolute bottom-0 right-0 text-white font-bold py-2 px-4 m-4 rounded" onClick={() => setIsOpen(true)}>
                                Open gallery
                            </button>
                        </div>
                    </div>
                </div>
            </div>

            <div class="max-w-6xl mx-auto">
                <h1 class="align-middle	text-3xl tracking-tight font-bold text-gray-900 mb-2 ml-2 mt-4">Amenities</h1>
                <div class="grid grid-cols-1 md:grid-cols-3 max-w-6xl mx-auto">
                    <div class="max-w rounded overflow-hidden border border-sky-500 m-2">
                        <div class="font-bold text-xl bg-gray-100 p-2">Entertainment</div>
                        <hr />
                        <div class="px-6 py-4">
                            <CardTitle icon={<FaTv size={25} />} title={'TV with Netflix, standard cable/satellite'} />
                            <hr />
                            <CardTitle icon={<FaMusic size={25} />} title={'4 zone speaker system including front yard Bluetooth sound system'} />
                            <hr />
                            <CardTitle icon={<FaEthernet size={25} />} title={'Ethernet connection'} />
                            <hr />
                            <CardTitle icon={<FaDumbbell size={25} />} title={'Exercise equipment: free weights, stationary bike, treadmill, yoga mat'} />
                            <hr />
                            <CardTitle icon={<FaBook size={25} />} title={'Books and reading material'} />
                        </div>
                    </div>
                    <div class="max-w rounded overflow-hidden border border-sky-500 m-2">
                        <div class="font-bold text-xl bg-gray-100 p-2">About the property</div>
                        <hr />
                        <div class="px-6 py-4">
                            <CardTitle icon={<IoBalloon size={25} />} title={'Suitable for events'} />
                            <hr />
                            <CardTitle icon={<FaCalendarAlt size={25} />} title={'The listing can accommodate a gathering of 25 or more attendees'} />
                            <hr />
                            <CardTitle icon={<FaWater size={25} />} title={'Lake View'} />
                            <hr />
                            <CardTitle icon={<FaUtensils size={25} />} title={'Kitchen'} />
                            <hr />
                            <CardTitle icon={<FaCarAlt size={25} />} title={'Free parking'} />
                            <hr />
                            <CardTitle icon={<FaPaw size={25} />} title={'Pets allowed'} />
                        </div>
                    </div>
                    <div class="max-w rounded overflow-hidden border border-sky-500 m-2">
                        <div class="font-bold text-xl bg-gray-100 p-2">Kitchen & Dining</div>
                        <hr />
                        <div class="px-6 py-4">
                            <CardTitle icon={<MdKitchen size={25} />} title={'Refrigerator & Freezer'} />
                            <hr />
                            <CardTitle icon={<MdMicrowave size={25} />} title={'Microwave'} />
                            <hr />
                            <CardTitle icon={<FaSoap size={25} />} title={'Dishwasher'} />
                            <hr />
                            <CardTitle icon={<MdDinnerDining size={25} />} title={'Dining Table'} />
                            <hr />
                            <CardTitle icon={<GiToaster size={25} />} title={'Toaster'} />
                            <hr />
                            <CardTitle icon={<FaCoffee size={25} />} title={'Kettle'} />
                        </div>
                    </div>
                    <div class="max-w rounded overflow-hidden border border-sky-500 m-2">
                        <div class="font-bold text-xl bg-gray-100 p-2">Outdoor</div>
                        <hr />
                        <div class="px-6 py-4">
                            <CardTitle icon={<MdOutlineGrass size={25} />} title={'Private Garden'} />
                            <hr />
                            <CardTitle icon={<MdOutlineFireplace size={25} />} title={'Firepit'} />
                            <hr />
                            <CardTitle icon={<FaChair size={25} />} title={'Outdoor Furniture'} />
                            <hr />
                            <CardTitle icon={<MdOutdoorGrill size={25} />} title={'BBQ Grill'} />
                            <hr />
                            <CardTitle icon={<MdOutlinePedalBike size={25} />} title={'Bikes'} />
                            <hr />
                            <CardTitle icon={<MdKayaking size={25} />} title={'Kayak'} />
                        </div>
                    </div>
                    <div class="max-w rounded overflow-hidden border border-sky-500 m-2">
                        <div class="font-bold text-xl bg-gray-100 p-2">Bedrooms & Bathrooms</div>
                        <hr />
                        <div class="px-6 py-4">
                            <CardTitle icon={<FaBath size={25} />} title={'Bath'} />
                            <hr />
                            <CardTitle icon={<FaToiletPaper size={25} />} title={'Toiletries'} />
                            <hr />
                            <CardTitle icon={<MdAir size={25} />} title={'Hairdryer'} />
                            <hr />
                            <CardTitle icon={<MdOutlineLocalLaundryService size={25} />} title={'Dryer'} />
                            <hr />
                            <CardTitle icon={<MdIron size={25} />} title={'Iron'} />
                            <hr />
                            <CardTitle icon={<BsSafe2Fill size={25} />} title={'Safe'} />
                        </div>
                    </div>
                    <div class="max-w rounded overflow-hidden border border-sky-500 m-2">
                        <div class="font-bold text-xl bg-gray-100 p-2">Family</div>
                        <hr />
                        <div class="px-6 py-4">
                            <CardTitle icon={<FaBath size={25} />} title={'Cot & Travel Cot'} />
                            <hr />
                            <CardTitle icon={<MdToys size={25} />} title={'Childrens Books & Toys'} />
                            <hr />
                            <CardTitle icon={<GiDirectorChair size={25} />} title={'High Chair'} />
                            <hr />
                            <CardTitle icon={<MdMonitor size={25} />} title={'Baby Monitor'} />
                            <hr />
                            <CardTitle icon={<GiWoodenFence size={25} />} title={'Baby Safety Gates'} />
                            <hr />
                            <CardTitle icon={<FaBaby size={25} />} title={'Babysitter Recommendations'} />
                        </div>
                    </div>
                </div>
            </div>


            <Footer />
        </div>
    );
    function CardTitle({ title, icon }) {
        return (
            <div class="flex md:flex-row flex-wrap mt-2 mb-2">
                <div class="w-full w-1/6">
                    {icon}
                </div>
                <div class="w-full w-5/6">{title}</div>
            </div>
            // { React.createElement(icon, { width: 12, height: 12, color: '#000' }) }
        )
    };
}


export default Accommodation;