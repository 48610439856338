import './NavBar.css';
import Button from 'react-bootstrap/Button';
import Container from 'react-bootstrap/Container';
import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';
import 'bootstrap/dist/css/bootstrap.css';
import { useState } from 'react';
import NavBarAlt from './NavBarAlt';

// Images
import imagepaths from '../../images/imagepaths';


function NavBar() {
  const [navbar, setNavbar] = useState(false);

  const changeNavBarColour = () => {
    setNavbar(window.scrollY >= 80);
  }

  const [expanded, setExpanded] = useState(false)

  const toggleExpanded = () => {
    setExpanded(!expanded);
  }

  function isDropdownShowing() {
    if (expanded) return true;
    return navbar;
  }

  function bookNowFunc() {
    window.open("https://checkout.lodgify.com/andy-biggart-f15c14/en/?currency=GBP#/396230")
  }

  window.addEventListener('scroll', changeNavBarColour);

  return (
    <div>
      <Navbar expand="lg" fixed='top' variant={isDropdownShowing() ? 'light' : 'dark'} className={isDropdownShowing() ? 'navbar active' : 'navbar'} onToggle={toggleExpanded}>
        <Container class="NavBarText">
          <Navbar.Brand href="/"><h4 class={isDropdownShowing() ? "nav-link active" : "nav-link"}>EALA LODGE</h4></Navbar.Brand>
          <Navbar.Toggle />
          <Navbar.Collapse className="justify-content-end">
            <Nav.Link href="https://checkout.lodgify.com/andy-biggart-f15c14/en/?currency=GBP#/396230" target="_black"><h6 class={isDropdownShowing() ? "nav-link active" : "nav-link"}>BOOK NOW</h6></Nav.Link>
            <Nav.Link href="/accommodation"><h6 class={isDropdownShowing() ? "nav-link active" : "nav-link"}>GALLERY</h6></Nav.Link>
            <Nav.Link href="/cookingexperiences"><h6 class={isDropdownShowing() ? "nav-link active" : "nav-link"}>EXPERIENCES</h6></Nav.Link>
            <Nav.Link href="/contactus"><h6 class={isDropdownShowing() ? "nav-link active" : "nav-link"}>CONTACT US</h6></Nav.Link>
          </Navbar.Collapse>
        </Container>
      </Navbar>
      <div class="HeaderImageOverlay">
        <img src={imagepaths.coverImage} class="headerImage h-screen" />
        <div class="text-on-image">
          <h2 class="text-5xl font-light leading-normal text-white">EALA LODGE & EALA LODGE EXPERIENCE</h2>
          {/* <h2 class="text-4xl font-light leading-normal text-white">Lorem ipsum dolor sit amet</h2> */}
          <button class="bg-black/30 text-white font-semibold text-5x1 hover:text-white py-2 px-4 mt-4 border border-blue-500 hover:border-transparent" onClick={bookNowFunc}>BOOK NOW</button>
        </div>
      </div>
    </div>
  );
}

export default NavBar;
