import NavBarAlt from "../components/Navigation/NavBarAlt";

function About() {
    return (
        <div>
            <NavBarAlt title="About Us" image="https://a0.muscache.com/im/pictures/b594d43c-59ec-4bde-a6f0-12155d1508e3.jpg?im_w=1440" />
            <h1 class="align-middle	text-center text-4xl tracking-tight font-extrabold text-gray-900 mt-10 mb-4">Welcome to Eala Lodge</h1>
        </div>
    );
}
export default About;