import 'bootstrap/dist/css/bootstrap.css';
import Button from "react-bootstrap/Button";
import Nav from 'react-bootstrap/Nav';
import { Link } from 'react-router-dom';

import imagepaths from '../../images/imagepaths';


function HomepageCards() {
    return (
        <div class="grid grid-cols-1 md:grid-cols-3 max-w-6xl mx-auto">
            <div class={"max-w-sm rounded overflow-hidden shadow-lg m-4"}>
                <img class="w-full object-cover h-64" src="https://a0.muscache.com/im/pictures/6c81959b-fe90-4819-a8ad-5733b2cb315c.jpg?im_w=1440" alt="Sunset in the mountains" />
                <div class="px-6 py-4">
                    <div class="font-bold text-xl mb-2  text-gray-700">Eala Lodge Lake</div>
                    <p class="text-gray-700 text-base font-sans">
                        Eala Lodge has its own lake stocked with brown trout fed by a river that winds its way through the property that guests can fish by arrangement.
                    </p>
                </div>
            </div>
            <div class={"max-w-sm rounded overflow-hidden shadow-lg m-4"}>
                <img class="w-full object-cover h-64" src={imagepaths.outside1} alt="Sunset in the mountains" />
                <div class="px-6 py-4">
                    <div class="font-bold text-xl mb-2  text-gray-700">Accommodation</div>
                    <p class="text-gray-700 text-base font-sans">
                        <ul class="list-none p-0 m-0 columns-2	mb-2">
                            <li>6 Bedrooms</li>
                            <li>9 Beds</li>
                            <li>4 Bathrooms</li>
                            <li>2 Sitting Rooms</li>
                        </ul>
                    </p>
                </div>
                <Nav.Link href='/accommodation'><p class="text-gray-700 text-base font-sans font-bold underline m-2">Show More ></p></Nav.Link>
            </div>
            <div class="max-w-sm rounded overflow-hidden shadow-lg m-4">
                <img class="w-full object-cover h-64" src="https://northernirelandholidays.co.uk/wp-content/uploads/2017/01/Downhill-1-e1486782206850.jpg" alt="Sunset in the mountains" />
                <div class="px-6 py-4">
                    <div class="font-bold text-xl mb-2 text-gray-700">The Location</div>
                    <p class="text-gray-700 text-base">
                        The property is located in the hills just above Castlerock and is surrounded by maturing native trees like oaks and willow.
                    </p>
                </div>
            </div>
        </div>
    );
}

export default HomepageCards;