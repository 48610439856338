import React, { Component, useRef } from 'react';
import Footer from '../components/Navigation/Footer';
import NavBarAltFullScreen from '../components/Navigation/NavBarAltFullScreen';
import imagepaths from '../images/imagepaths';

function CookingExp() {
    return (
        <div>
            <NavBarAltFullScreen image={imagepaths.gallery5} title="Coming Soon" />
            <Footer />
        </div>
    );
};

export default CookingExp;