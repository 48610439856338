import React, { Component, useRef } from 'react';
import './Spinner.css';


function Spinner() {
    return (
        <div class="mt-6 text-center p-10">
            <div class="lds-ring">
                <div>
                </div>
                <div>
                </div>
                <div>
                </div>
                <div>
                </div>
            </div>
        </div>

    );
};

export default Spinner;