
import './Footer.css';
import { FaPhone, FaEnvelope, FaMapMarkerAlt } from 'react-icons/fa';

function Footer() {
    return (
        <div class="bg-gray-100 border-t border-neutral-200 mt-6 ">
            <div class="max-w-3xl mx-auto mt-2 mb-2">
                <div class="grid grid-cols-1 md:grid-cols-3 font-semibold text-center">
                    <div class="">
                        <FaPhone size={30} class="mx-auto mb-2 mt-10" />
                        07813 047025
                    </div>
                    <div class="">
                        <FaEnvelope size={30} class="mx-auto mb-2 mt-10" />
                        ealalodge@yahoo.com
                    </div>
                    <div >
                        <FaMapMarkerAlt size={30} class="mx-auto mb-2 mt-10" />
                        48 Glebe Rd, Castlerock, Coleraine, BT51 4SW
                    </div>
                </div>
            </div>
            <div class="max-w-3xl mx-auto mt-2 pb-10">
                <hr class="m-4" />
                <div class="grid grid-cols-1 md:grid-cols-5 font-semibold text-center">
                    <div class="m-2">
                        <a href="/">Home</a>
                    </div>
                    <div class="m-2">
                        <a href="https://checkout.lodgify.com/andy-biggart-f15c14/en/?currency=GBP#/396230" target="_black">Book Now</a>
                    </div>
                    <div class="m-2">
                        <a href="/accommodation">Gallery</a>
                    </div>
                    <div class="m-2">
                        <a href="/cookingexperiences">Experiences</a>
                    </div>
                    <div class="m-2">
                        <a href="/contactus">Contact Us</a>
                    </div>
                </div>

                {/* <p class="float-left">
                    Home    BookNow     Gallery     Cooking Experiences     Contact Us
                    <span class="float-right">
                        © 2022 Eala Lodge
                    </span>
                </p> */}
            </div>
            <div class="bg-gray-200 border-t border-neutral-300 mt-6 text-center p-1">
                Website made by EasyDev
            </div>
        </div>
    );
}

export default Footer;