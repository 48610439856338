import React, { Component, useRef, useState } from 'react';
import NavBarAlt from '../components/Navigation/NavBarAlt';
import Footer from '../components/Navigation/Footer';
import { Formik } from 'formik';
import './ContactUs.css';
import emailjs from '@emailjs/browser';
import imagepaths from '../images/imagepaths';
import { MoonLoader } from 'react-spinners';
import Spinner from '../components/Spinner';

function ContactUs() {

    const [status, setStatus] = useState("Submit");
    const [showSpinner, setShowSpinner] = useState(false);

    const handleSubmitForm = async (e) => {
        e.preventDefault();
        setStatus("Sending...");
        setShowSpinner(true);
        const { firstname, lastname, email, phone, response } = e.target.elements;

        let details = {
            firstname: firstname.value,
            lastname: lastname.value,
            email: email.value,
            phone: phone.value,
            response: response.value,
        };

        let responsepost = await fetch("https://intelligent-shamir.77-68-100-131.plesk.page/contact", {
            method: "POST",
            headers: {
                "Content-Type": "application/json;charset=utf-8",
            },
            body: JSON.stringify(details),
        });
        setStatus("Submit");
        let result = await responsepost.json();

        if (result.status == 'Message Sent') {
            window.open("/contactsuccess", "_self");
        }
        else {
            alert(result.status);
        }



    };

    return (
        <div >
            <NavBarAlt title="Contact Us" image={imagepaths.outside2} />
            {showSpinner ? <div>
                <Spinner />
            </div> :
                <div class="max-w-3xl mx-auto mt-10 mb-20">
                    <h3 class="text-3xl font-bold mb-3 mx-3">Contact Form</h3>
                    <p class="text-base font-semibold mb-3 mx-3">
                        Fill out and submit the contact form and we will get back to you. Alternatively, you can reach us via phone or email.
                    </p>
                    <Formik
                        initialValues={{ firstname: '', lastname: '', email: '', phone: '', response: '' }}
                        validate={values => {
                            return {};
                            const errors = {};
                            if (!values.email) {
                                errors.email = 'Required';
                            } else if (
                                !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)
                            ) {
                                errors.email = 'Invalid email address';
                            }
                            return errors;
                        }}
                        onSubmit={(values, { setSubmitting }) => {
                            setTimeout(() => {
                                setSubmitting(false);
                            }, 400);
                        }}
                    >
                        {({
                            values,
                            errors,
                            touched,
                            handleChange,
                            handleBlur,
                            handleSubmit,
                            isSubmitting,
                            /* and other goodies */
                        }) => (
                            <form onSubmit={handleSubmitForm}>
                                <div class="grid grid-cols-1 md:grid-cols-2 font-semibold text-center">

                                    <div class="mx-3 mt-3 mb-0">
                                        <p class="text-left text-sm">First Name</p>
                                        <input
                                            class="w-full"
                                            name="firstname"
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            value={values.firstname}
                                        />
                                    </div>
                                    <div class="mx-3 mt-3 mb-0">
                                        <p class="text-left text-sm">Last Name</p>
                                        <input
                                            class="w-full"
                                            name="lastname"
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            value={values.lastname}
                                        />
                                    </div>


                                    <div class="mx-3 mt-3 mb-0">
                                        <p class="text-left text-sm">Email Address</p>
                                        <input
                                            class="w-full"
                                            type="email"
                                            name="email"
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            value={values.email}
                                        />
                                    </div>
                                    {errors.email && touched.email && errors.email}
                                    <div class="mx-3 mt-3 mb-0">
                                        <p class="text-left text-sm">Phone Number</p>
                                        <input
                                            class="w-full"
                                            name="phone"
                                            type="phone"
                                            maxlength="30"
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            value={values.phone}
                                        />
                                    </div>
                                    {errors.password && touched.password && errors.password}
                                </div>
                                <div class="grid grid-rows-1 md:grid-rows-1 font-semibold text-center">
                                    <div class="mx-3 mt-3 mb-0">
                                        <p class="text-left text-sm">Your Inquiry</p>
                                        <textarea
                                            rows={5}
                                            class="w-full"
                                            name="response"
                                            maxlength="1500"
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            value={values.response}
                                        />
                                    </div>
                                    <div class="mx-3 mt-3 mb-0">
                                        <input type="submit" value="Submit" class="button w-full bg-sky-600	" />
                                    </div>
                                </div>
                            </form>
                        )}
                    </Formik>
                </div>
            }
            <Footer />
        </div >
    );
}

export default ContactUs;

// import React, { useState } from "react";

// const ContactForm = () => {
//   const [status, setStatus] = useState("Submit");
//   const handleSubmit = async (e) => {
//     e.preventDefault();
//     setStatus("Sending...");
//     const { name, email, message } = e.target.elements;
//     let details = {
//       name: name.value,
//       email: email.value,
//       message: message.value,
//     };
//     let response = await fetch("http://localhost:5000/contact", {
//       method: "POST",
//       headers: {
//         "Content-Type": "application/json;charset=utf-8",
//       },
//       body: JSON.stringify(details),
//     });
//     setStatus("Submit");
//     let result = await response.json();
//     alert(result.status);
//   };
//   return (
//     <form onSubmit={handleSubmit}>
//       <div>
//         <label htmlFor="name">Name:</label>
//         <input type="text" id="name" required />
//       </div>
//       <div>
//         <label htmlFor="email">Email:</label>
//         <input type="email" id="email" required />
//       </div>
//       <div>
//         <label htmlFor="message">Message:</label>
//         <textarea id="message" required />
//       </div>
//       <button type="submit">{status}</button>
//     </form>
//   );
// };

// export default ContactForm;