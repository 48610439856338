import React, { Component, useRef } from 'react';
import Footer from '../components/Navigation/Footer';
import imagepaths from '../images/imagepaths';
import NavBarAlt from '../components/Navigation/NavBarAlt';

function ContactUsSuccess() {
    return (
        <div>
            <NavBarAlt image={imagepaths.gallery5} title="Inquiry sent successfully" />
            <div class="pt-10 pb-10">
                <p class="text-center text-2xl font-bold">Thank you</p>
                <p class="text-center">You will hear back from us soon!</p>
            </div>
            <Footer />
        </div>
    );
};

export default ContactUsSuccess;